//@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #ffffff;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

.pro-sidebar-header {
  border-bottom: none;
  margin-top: 26px;
  margin-left: 26px;
}

@import '~react-pro-sidebar/dist/scss/styles.scss';

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.app {
  text-align: left;
  font-family: Montserrat, sans-serif;
  letter-spacing: 0;
  color: #B9B9B9;
  opacity: 1;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        margin-left: 5px;
        font-size: 13px;
      }

      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  .pro-sidebar.collapsed {
    width: 131px;
    min-width: 131px;
  }

  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
    list-style-type: none;
    //padding: 20px;
  }

  .pro-sidebar {
    color: #adadad;
    height: 100%;
    width: 300px;
    min-width: 300px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;
  }

  .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: unset;
    overflow-x: unset;
  }

  .sidebarCollapseButton, .sidebarCollapsedButton {
    border: 2px #9B008F solid;;
    height: 40px;
    width: 40px;
    min-height: 40px;
    border-radius: 50%;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .3);
    transform: translateX(-50%);
    cursor: pointer;
    margin-left: 100%;
    top: 50%;
    position: relative;
    z-index: 16;
  }

  .sidebarCollapseButton:after {
    border: 3px solid #9B008F;
    border-top: none;
    border-right: none;
    content: "";
    height: 12px;
    left: 14px;
    position: absolute;
    top: 12px;
    transform: rotate(45deg);
    width: 12px;
  }

  .sidebarCollapsedButton:after {
    border: 3px solid #9B008F;
    border-top: none;
    border-right: none;
    content: "";
    height: 12px;
    left: 10px;
    position: absolute;
    top: 12px;
    transform: rotate(225deg);
    width: 12px;
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .container-fluid {
      z-index: 10;
    }

    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;

      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;

      a {
        text-decoration: none;
        color: #888;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-bagdes {
    margin-top: 10px;

    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;

    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }

    .block {
      direction: rtl;

      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  .rdashbord-blocks-left {
    min-width: 525px;
    display: flex;
    flex-direction: column;
    width: 49%
  }
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 15px;
  padding-left: 20px;
  max-height: 40px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  flex-grow: 0;
  margin-left: 5px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0 0 0 20px;
  margin-bottom: 10px;
}

.rlogo-wrapper {
  padding: 24px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
}

.rlogo-wrapper {
  background-image: url('../../../../assets/img/wellness-cloud-logo.png');
  background-size: 200px 50px;
  background-repeat: no-repeat;
  background-position: center;
  width: 200px;
  height: 50px;
  margin-left: 18px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.rlogo-wrapper-collapsed {
  background-image: url('../../../../assets/img/logo-without-text.jpg');
  background-size: 33px 25px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 18px;
  width: 33px;
  height: 25px;
  cursor: pointer;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  font-weight: bold;
  color: white;
  border-width: 5px;
  border-left-style: solid;
  padding-left: 15px;
  border-image: linear-gradient(
                  to bottom,
                  #ff5201,
                  rgb(1, 45, 213)
  ) 1 100%;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  letter-spacing: 0;
}

.pro-sidebar-footer {
  margin-left: 40px;
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

@media (max-width: 1366px) {
  .pro-sidebar-header {
    border-bottom: none;
    margin-top: 26px;
    margin-left: 5px;
  }
}

@media (max-width: 1366px) {
  .pro-sidebar-footer {
    margin-left: 25px;
  }
}

@media (max-width: 1366px) {
  .rlogo-wrapper {
    background-image: url('../../../../assets/img/wellness-cloud-logo.png');
    background-size: 160px 38px;
    background-repeat: no-repeat;
    background-position: center;
    width: 160px;
    height: 38px;
    margin-left: 18px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }
}

@media (max-width: 1366px) {
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 0 0 0 20px;
    margin-bottom: 10px;
    margin-left: -20px;
  }
}

@media (max-width: 1366px) {
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    flex-grow: 0;
    margin-left: 5px;
  }
}

.app {

  @media (max-width: 1366px) {
    .pro-sidebar.collapsed {
      width: 80px;
      min-width: 80px;
    }
  }
  @media (max-width: 1366px) {
    .pro-sidebar {
      color: #adadad;
      height: 100%;
      width: 270px;
      min-width: 250px;
      text-align: left;
      transition: width, left, right, 0.3s;
      position: relative;
      z-index: 1009;
    }
  }
  @media (max-width: 1366px) {
    .rdashbord-blocks-left {
      padding-left: 5px;
      margin-right: 5px;
      display: flex;
      flex-direction: column;
      width: 49%
    }
  }

  @media (max-width: 1024px) {
    .rdashbord-blocks-left {
      padding-left: 5px;
      margin-right: 5px;
      display: flex;
      flex-direction: column;
      width: 90%
    }
  }
}